import { CmsConfigRendererProps } from "@/components/cms/cmsConfigModal/cmsConfigRenderer";
import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfigModalTabs from "@/hooks/useConfigModalTabs";
import { StrapiUploadFile } from "@/types/strapi";
import { deepImmutableCopy } from "@/utils/util";
import CmsContentImage from "../cmsContent/cmsContentImage";
import GenericConfigTabExtender from "./genericConfigTabExtender";
import GenericConfigTabs from "./genericConfigTabs";

interface CmsGenericConfigProps extends CmsConfigRendererProps {}

export default function CmsImageWithMarkersConfig(
  props: CmsGenericConfigProps
) {
  const tCms = useCmsTranslation();
  const { configModalTabs, filterConfigModalFieldsFromConfigModalTabs } =
    useConfigModalTabs(props.attributes, props.component);

  // filter elements that you want to render with custom logic
  const filteredConfigModalTabs = filterConfigModalFieldsFromConfigModalTabs(
    deepImmutableCopy(configModalTabs),
    ["cfgImg", "cfgImageWidth", "cfgImageHeight"]
  );

  const storeSetting = props.currentElement["cfgImg"];

  const WIDTH_DEFAULT = 800;
  const HEIGHT_DEFAULT = 600;

  const calculateHeight = (
    originalWidth: number,
    originalHeight: number,
    newWidth: number
  ): number => {
    if (!originalWidth || !originalHeight || !newWidth) {
      return HEIGHT_DEFAULT;
    }
    const aspectRatio = originalHeight / originalWidth;
    const newHeight = newWidth * aspectRatio;
    return Math.floor(newHeight);
  };

  return (
    <GenericConfigTabs
      configModalTabs={filteredConfigModalTabs}
      onChange={props.onChange}
      key={storeSetting}
    >
      <GenericConfigTabExtender tabName="options" index={1}>
        <ModalInputWrapper
          label={tCms("configModal-cfgImageWidth")}
          description={tCms("configModal-cfgImageWidthDescription")}
        >
          <PbInput
            type="number"
            fullWidth
            defaultValue={props.currentElement.cfgImageWidth}
            onChange={(e) => {
              props.onChange("cfgImageWidth", e.target.value);
              if (e.target.value) {
                if (props.currentElement.cfgImg) {
                  const newHeight = calculateHeight(
                    props.currentElement.cfgImg.width ?? WIDTH_DEFAULT,
                    props.currentElement.cfgImg.height ?? HEIGHT_DEFAULT,
                    e.target.value
                  );
                  props.onChange("cfgImageHeight", newHeight);
                } else {
                  props.onChange("cfgImageHeight", HEIGHT_DEFAULT);
                }
              }
            }}
          />
        </ModalInputWrapper>
        <ModalInputWrapper
          label={tCms("configModal-cfgImg")}
          description={tCms("configModal-cfgImgDescription")}
        >
          <CmsContentImage
            isMobile={false}
            img={props.currentElement.cfgImg}
            format="thumbnail"
            onChange={(img?: StrapiUploadFile) => {
              if (!img) {
                // img was removed
                props.onChange("cfgImg", null);
                // reset all marker and text positions
                if (props.currentElement.content) {
                  props.currentElement.content.forEach(
                    (element: any, index: number) => {
                      props.onChange(`content[${index}].markerPosition`, {
                        x: 1,
                        y: 1,
                      });
                      props.onChange(`content[${index}].position`, {
                        x: 1,
                        y: 1,
                      });
                    }
                  );
                }
              } else {
                // image selected
                const newHeight = calculateHeight(
                  img.width ?? WIDTH_DEFAULT,
                  img.height ?? HEIGHT_DEFAULT,
                  props.currentElement.cfgImageWidth ?? WIDTH_DEFAULT
                );
                props.onChange(
                  "cfgImageWidth",
                  props.currentElement.cfgImageWidth ?? WIDTH_DEFAULT
                );
                props.onChange("cfgImageHeight", newHeight);
                props.onChange("cfgImg", img);
              }
            }}
          />
        </ModalInputWrapper>
      </GenericConfigTabExtender>
    </GenericConfigTabs>
  );
}
